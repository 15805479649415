import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import NextLink from 'next/link';
import {
	Container, Flex, Box, Heading, Input, VStack, Stack, Text, AspectRatio,
	StackDivider, Link as ChakraLink, Divider, Tooltip, Spacer, Icon,
} from '@chakra-ui/react';
import CountUp from 'react-countup';
import pangu from 'pangu';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons';
import { NextSeo } from 'next-seo';
import 'dayjs/locale/zh-tw';
import Page from '../components/Page';
import { serversideInstance as axios } from '../utils/axios';

const WordCloud = dynamic(() => import('../components/Wordcloud'), { ssr: false });

export default function Home({
	terms, count, latestDatasets, lastModifiedDatasets,
}) {
	const [keyword, setKeyword] = useState('');

	const search = () => {
		if (globalThis.umami) {
			globalThis.umami.track('home:search', { label: keyword });
		}
		Router.push({
			pathname: '/datasets/search',
			query: { s: keyword },
		});
	};

	dayjs.extend(relativeTime);
	dayjs.locale('zh-tw');

	return (
		<Page>
			<NextSeo
				title="ODPortal | 臺灣開放資料集散地"
				description="Open Data Portal | 臺灣開放資料集散地"
			/>
			<Flex mt={10}>
				<Box>
					{ count && (
						<Heading as="h2" fontSize="3xl" fontWeight={400}>
							從臺灣的
							{' '}
							<CountUp separator="," end={count} />
							{' '}
							份開放資料集中搜尋
						</Heading>
					)}
				</Box>
			</Flex>
			<Flex>
				<Box w="100%">
					<Input
						px={2}
						my={1}
						borderColor="#777"
						variant="flushed"
						type="search"
						placeholder="搜尋資料集..."
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
						onKeyPress={(e) => { if (e.key === 'Enter') { search(); } }}
					/>
				</Box>
			</Flex>
			<Flex>
				<Box w="100%" mt={4}>
					<Heading as="h3" fontSize="md" mb={4}>或可以試試看下面這些熱門關鍵字：</Heading>
					<Box maxW="100%" minH="200px">
						<AspectRatio ratio={10 / 4} minH="200px">
							<WordCloud data={terms} />
						</AspectRatio>
					</Box>
				</Box>
			</Flex>
			<Container maxW="container.lg">
				<Stack direction={['column', 'row']} mt={[2, 12]} spacing={5}>
					<Box width="100%">
						{latestDatasets && (
							<Box p={5} shadow="lg" rounded="sm" backgroundColor="#fff">
								<Flex mb={2}>
									<Heading as="h3" pt={1} fontSize="md">最新上架資料集</Heading>
									<Spacer />
									<Box>
										<NextLink href="/feed/latest" passHref>
											<ChakraLink d="inline-block" ml={2} data-umami-event="home:latest-feed">
												<Tooltip label="訂閱最新上架資料集" aria-label="訂閱資料集">
													<Box>
														<Icon color="#EE802F" as={Fa} icon={faRssSquare} size="sm" />
													</Box>
												</Tooltip>
											</ChakraLink>
										</NextLink>
										<NextLink href="/datasets/search?mode=latestDatasets" passHref>
											<ChakraLink d="inline-block" ml={2} data-umami-event="home:more-latest">
												<Tooltip label="顯示更多最新上架資料集" aria-label="顯示資料集">
													<Text fontSize="sm">查看更多</Text>
												</Tooltip>
											</ChakraLink>
										</NextLink>
									</Box>
								</Flex>
								<Divider mb={4} />
								<VStack
									divider={<StackDivider borderColor="gray.200" />}
									spacing={4}
									align="start"
								>
									{latestDatasets.map((d) => (
										<Box>
											<NextLink href={`/dataset/${d.nanoId}`} passHref>
												<ChakraLink data-umami-event="home:latest-dataset" data-umami-event-dataset={d.nanoId}>
													<Tooltip label={pangu.spacing(d.name)} aria-label="資料集名稱">
														<Text noOfLines={1}>{pangu.spacing(d.name)}</Text>
													</Tooltip>
												</ChakraLink>
											</NextLink>
											<Text fontSize="sm" color="#777" mt={1}>
												{d?.government?.name}
												{' · '}
												{dayjs(d.createdAt).fromNow()}
												上架
											</Text>
										</Box>
									))}
								</VStack>
							</Box>
						)}
					</Box>
					<Box width="100%">
						{lastModifiedDatasets && (
							<Box p={5} shadow="lg" rounded="sm" backgroundColor="#fff">
								<Flex mb={2}>
									<Heading as="h3" pt={1} fontSize="md">最新異動資料集</Heading>
									<Spacer />
									<Box>
										<NextLink href="/feed/lastModified" passHref>
											<ChakraLink d="inline-block" ml={2} data-umami-event="home:modified-feed">
												<Tooltip label="訂閱最新上架資料集" aria-label="訂閱資料集">
													<Box>
														<Icon color="#EE802F" as={Fa} icon={faRssSquare} size="sm" />
													</Box>
												</Tooltip>
											</ChakraLink>
										</NextLink>
										<NextLink href="/datasets/search?mode=lastModified" passHref>
											<ChakraLink d="inline-block" ml={2} data-umami-event="home:more-modified">
												<Tooltip label="顯示更多最新異動資料集" aria-label="顯示資料集">
													<Text fontSize="sm">查看更多</Text>
												</Tooltip>
											</ChakraLink>
										</NextLink>
									</Box>
								</Flex>
								<Divider mb={4} />
								<VStack
									divider={<StackDivider borderColor="gray.200" />}
									spacing={4}
									align="start"
								>
									{lastModifiedDatasets.map((d) => (
										<Box>
											<NextLink href={`/dataset/${d.nanoId}`} passHref>
												<ChakraLink data-umami-event="home:modified-dataset" data-umami-event-dataset={d.nanoId}>
													<Tooltip label={pangu.spacing(d.name)} aria-label="資料集名稱">
														<Text noOfLines={1}>{pangu.spacing(d.name)}</Text>
													</Tooltip>
												</ChakraLink>
											</NextLink>
											<Text fontSize="sm" color="#777" mt={1}>
												{d?.government?.name}
												{' · '}
												{dayjs(d.lastModified).fromNow()}
												變更
											</Text>
										</Box>
									))}
								</VStack>
							</Box>
						)}
					</Box>
				</Stack>
			</Container>
		</Page>
	);
}

export async function getStaticProps() {
	const { data: terms } = await axios.get('/api/v1/terms');
	const { data: count = 0 } = await axios.get('/api/v2/od/summary');
	const { data: latestDatasets } = await axios.get('/api/v1/datasets?limit=5');
	const { data: lastModifiedDatasets } = await axios.get('/api/v1/datasets?limit=5&sort=lastModified');

	return {
		props: {
			terms,
			count,
			latestDatasets,
			lastModifiedDatasets,
		},
		revalidate: 300,
	};
}
